$xs:  "only screen and (max-width:575px)";
$sm:  "only screen and (min-width:576px) and (max-width:767px)";
$md:  "only screen and (min-width:768px) and (max-width:991px)";
$lg: "only screen and (min-width:992px) and (max-width:1199px)";
$xl:  "only screen and (min-width:1200px)";
$xxl:  "only screen and (min-width:2000px)";
header{
  border-top: 10px solid;
  border-image: linear-gradient(90deg, #1C8148 50%, #8ECA55 100%) 1;
  background-color: #ffffff;
  @media #{$md},#{$sm},#{$xs} {
    z-index: 999;
    position: fixed;
    width: 100%;
  }
  @media #{$md} {
    height: 109px;
  }
  @media #{$sm},#{$xs} {
    height: 75px;
  }
}
body{
  font-family: 'M PLUS 1', sans-serif;
  &.hum-active{
    overflow: hidden;
  }
}
main{
  @media #{$md} {
    padding-top: 109px;
  }
  @media #{$sm},#{$xs} {
    padding-top: 75px;
  }
}
a{
  text-decoration: unset;
  color: inherit;
  &:hover{
    text-decoration: unset;
    color: inherit;
  }
  &.hum_in_link{
    display: block;
    color: #4C3D3A;
    font-weight: 500;
    position: relative;
    padding: 0 0 16px 21px;
    &::after{
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      font-weight: 600;
      color: #4C3D3A;
      right: 21px;
      top: 0;
    }
  }
  &.about_button{
    display: block;
    padding: 16px 0;
    margin-top: 32px;
    text-align: center;
    border-radius: 60px;
    background: #1C8148;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1.5px;
    position: relative;
    &::after{
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      color: #ffffff;
      font-weight: 600;
      right: 30px;
      top: 15px;
    }
    &:hover{
      background-color: rgba(28, 129, 72, 0.50);
    }
  }
  &.service_button{
    display: block;
    padding: 16px 0;
    margin-top: 42px;
    text-align: center;
    border-radius: 60px;
    background: #1C8148;
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 1.5px;
    position: relative;
    &::after{
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      color: #ffffff;
      font-weight: 600;
      right: 30px;
      top: 15px;
    }
    &:hover{
      background-color: rgba(28, 129, 72, 0.50);
    }
  }
  &.item_list{
    background: rgba(255, 255, 255, 0.90);
    box-shadow: 0px 0px 28px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    color: #1C8148;
    padding: 40px 0;
    text-align: center;
    font-weight: 500;
    border-radius: 10px;
    font-size: 24px;
    @media #{$md},#{$sm},#{$xs} {
      padding: 24px 0;
    }
    &::before{
      content: "";
      border-radius: 10px 0 0 10px;
      background: linear-gradient(180deg, #1C8148 0%, #8ECA55 100%);
      height: 100%;
      width: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      position: absolute;
      font-family: "Font Awesome 5 Free";
      content: "\f054";
      color: #1C8148;
      font-weight: 600;
      right: 30px;
      top: 35%;
    }
    &:hover{
      border: 1px solid #1C8148;
      box-sizing: border-box;
    }
  }
  &.to_top_link{
    background: #1c8148;
    border-radius: 30px 0 0 30px;
    color: #fff;
    display: block;
    font-weight: 500;
    letter-spacing: 1.5px;
    padding: 30px 16px 30px 16px;
    position: absolute;
    right: 0;
    height: 210px;
    top: -272px;
    writing-mode: vertical-rl;
    @media #{$md},#{$sm},#{$xs} {
      height: auto;
      top: -61px;
      border-radius: 12px 12px 0 0;
      padding: 12px 12px 8px;
      i{
        font-size: 30px;
      }
    }
    &:hover{
      background-color: rgba(28, 129, 72, 0.50);
    }
    &::after{
      content: "ページトップへ";
      position: absolute;
      @media #{$md},#{$sm},#{$xs} {
        content :"";
      }
    }
  }
  &.detail_link{
    color: #1c8148;
    font-weight: 500;
    letter-spacing: 1.5px;
    font-size: 22px;
    //position: absolute;
    bottom: 42px;
    right: 42px;
    //display: flex;
    align-items: center;
    @media #{$sm},#{$xs} {
      position: static;
      padding-top: 42px;
      justify-content: end;
    }
    &:hover{
      color: rgba(28, 129, 72, 0.50);
    }
    i{
      font-size: 18px;
      padding-left: 24px;
    }
  }
}
ul{
  margin: unset;
  padding-inline-start: unset;
  list-style: none;
  &.header_navi{
    display: flex;
    li{
      position: relative;
      color: #4C3D3A;
      &:first-child{
        padding-left: 32px;
      }
      &:last-child{
        padding-right: unset;
        &::after{
          background-color: unset;
        }
      }
      &::after{
        position: absolute;
        content: "";
        background-color: #4C3D3A;
        height: 19px;
        width: 1px;
        top: 3px;
        right: 0;
      }
      a{
        padding: 0 32px;
        position: relative;
        color: #4C3D3A;
        display: block;
        &:hover{
          color: #1C8148;
        }
      }
    }
  }
  &.menu_lists{
    max-width: 316px;
  }
  &.recommend_list{
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    li{
      color: #4c3d3a;
      font-weight: 400;
      letter-spacing: 1.25px;
      line-height: 32px;
      padding-right: 20px;
      &:last-child{
        padding-right: unset;
      }
    }
  }
  &.footer_lists{
    display: flex;
    justify-content: center;
    @media #{$md},#{$sm},#{$xs} {
      max-width: 316px;
      display: block;
    }
  }
  &.copy_lists{
    display: flex;
    @media #{$md},#{$sm},#{$xs} {
      order: 1;
    }
  }
}
li{
  &.menu_item{
    padding-top: 16px;
    &:first-child{
      padding-top: unset;
    }
  }
  &.footer_link{
    color: #4C3D3A;
    @media #{$xxl},#{$xl},#{$lg} {
      padding: 0 24px 36px 0;
    }
    @media #{$md},#{$sm},#{$xs} {
      padding-top: 16px;
    }
    &:first-child{
      padding-left: unset;
      @media #{$md},#{$sm},#{$xs} {
        padding-top: unset;
      }
    }
    &:last-child{
      @media #{$xxl},#{$xl},#{$lg} {
        padding-right: unset;
      }
    }
    a{
      @media #{$md},#{$sm},#{$xs} {
        color: #4c3d3a;
        display: block;
        font-weight: 500;
        padding: 0 0 16px 21px;
        position: relative;
        &::after{
          color: #4c3d3a;
          content: "\f054";
          font-family: Font Awesome\ 5 Free;
          font-weight: 600;
          position: absolute;
          right: 21px;
          top: 0;
        }
      }
      &:hover{
        @media #{$xxl},#{$xl},#{$lg} {
          color: #1C8148;
        }
      }
    }
  }
  &.copy_link{
    color: #ffffff;
    padding: 0 15px;
    font-weight: 300;
    font-size: 16px;
    &:first-child{
      padding-left: unset;
    }
    &:last-child{
      padding-right: unset;
    }
    a{
      text-decoration: underline;
      @media #{$md},#{$sm},#{$xs} {
        font-size: 13px;
      }
        &:hover{
        color: rgba(255,255,255,.5);
      }

    }
  }
}
nav {
  &.navi_bg {
    background-color: #F8F8F8;
    padding: 16px 0;
    box-shadow: inset 0 12px 15px -10px rgba(0, 0, 0, 0.10);
  }
  &.menu-content {
    box-shadow: inset 0 12px 15px -10px rgba(0, 0, 0, 0.10);
    background: rgba(255, 255, 255, 0.91);
    z-index: 80;
    left: 100%; /*leftの値を変更してメニューを画面外へ*/
    transition: all 0.5s; /*アニメーション設定*/
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 32px 37px 110px 37px;
    @media #{$md} {
      top: 154px;
    }
    @media #{$sm},#{$xs} {
      top: 121px;
    }
  }
}
p{
  &.top_logo{
    color :#4C3D3A;
    font-size: 35px;
    font-weight: 700;
    line-height: normal;
    padding: 24px 0 24px 64px;
    margin: unset;
    @media #{$md} {
      padding: 24px 0 24px 24px;
    }
    @media #{$sm},#{$xs} {
      font-size: 28px;
      padding: 12px 0 12px 20px;
    }
  }
  &.contents_text{
    color: #4C3D3A;
    line-height: 28px;
    //letter-spacing: 2px;
    margin-bottom: unset;
  }
  &.footer_logo{
    color: #4c3d3a;
    font-size: 36px;
    font-weight: 700;
    margin: unset;
    padding: 32px 0 40px;
    text-align: center;
    @media #{$md},#{$sm},#{$xs} {
      color: #FFFFFF;
      font-size: 24px;
      padding: 32px 0 24px 0;
    }
  }
  &.copy_write{
    color: #FFF;
    font-size: 16px;
    font-weight: 300;
    margin: unset;
    @media #{$md},#{$sm},#{$xs} {
      order: 2;
      text-align: center;
      padding: 16px 0;
      font-size: 13px;
    }
  }
  &.recommend_contractor{
    color: #4c3d3a;
    font-size: 22px;
    font-weight: 700;
    margin: unset;
    padding-top: 2rem;
  }
}
section{
  &.front_eye_catch{
    background-image: url("../../images/top_eyecatch.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 610px;
    background-position: bottom;
    @media #{$xxl} {
      height: 1040px;
    }
    @media #{$lg} {
      height: 518px;
    }
    @media #{$sm},#{$xs} {
      height: 520px;
      background-position-x: 57%;
    }
  }
  &.single_page_eye_catch{
    background-image: url("../../images/rectangle4.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    background-position: inherit;
    @media #{$xxl} {
      height: 400px;
      //background-position: bottom;
    }
    @media #{$lg} {
      height: 300px;
    }
    @media #{$md} {
      height: 250px;
    }
    @media #{$sm},#{$xs} {
      height: 178px;
      //background-position: center bottom;
    }

  }

  &.contents{
    padding-top: 160px;
    @media #{$md},#{$sm},#{$xs} {
      padding-top: 64px;
    }
  }
  &.bg_fluffy{
    bottom: 50px;
    position: fixed;
    right: 0;
    z-index: -1;
    width: 100%;
  }
}
h1{
  &.front_midasi{
    text-shadow: 0 0 15px #72757E;
    color: #ffffff;
    font-weight: 400;
    line-height: 70px;
    padding-top: 95px;
    @media #{$sm},#{$xs} {
      line-height: 42px;
      padding: 61px 0 0 20px;
    }
    span{
      font-size: 60px;
      letter-spacing: 4px;
      font-weight: 600;
      @media #{$sm},#{$xs} {
        font-size: 40px;
      }
      &.quotation{
        font-family: 'Noto Serif JP', serif;
      }
    }
  }
  &.asset_formation_title{
    font-family: 'Bruno Ace', sans-serif;
    color: #F8F8F8;
    font-size: 72px;
    font-weight: 400;
    margin: unset;
    padding-top: 40px;
    z-index: -1;
    @media #{$sm},#{$xs} {
      font-size: 40px;
      padding-top: 32px;
    }
  }
  &.other_page_midasi{
    text-align: center;
    margin-bottom: 80px;
    color: #4C3D3A;
    font-weight: 700;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: -1;
    @media #{$md},#{$sm},#{$xs} {
      margin-bottom: 50px;
    }
    &::after {
      content: "";
      margin: 0 auto;
      position: absolute;
      background-image: url("../../images/dot-line.svg");
      background-size: cover;
      background-repeat: no-repeat;
      bottom: -16px;
      width: 96px;
      height: 8px;
    }
  }
}
h2{
  &.about_shisan_midasi{
    color: #4C3D3A;
    font-size: 25px;
    font-weight: 700;
    line-height: normal;
  }
  &.dot_midasi{
    text-align: center;
    margin-bottom: 80px;
    color: #4C3D3A;
    font-weight: 700;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: -1;
    @media #{$md},#{$sm},#{$xs} {
      margin-bottom: 50px;
    }
    &::after {
      content: "";
      margin: 0 auto;
      position: absolute;
      background-image: url("../../images/dot-line.svg");
      background-size: cover;
      background-repeat: no-repeat;
      bottom: -16px;
      width: 96px;
      height: 8px;
    }
  }
}
h3{
  &.service_name{
    color: #1C8148;
    font-weight: 700;
    letter-spacing: 2.1px;
    margin: unset;
    padding-bottom: 42px;
  }
  &.other_asset_midasi{
    color: #1C8148;
    letter-spacing: 2.1px;
    font-weight: 700;
    background-color: rgba(28, 129, 72, 0.15);
    border-top: 10px solid #1C8148;
    padding: 12px 0 12px 24px;
    margin: unset;
  }
}
img{
  &.contents_img{
    border-radius: 29px;
    background-color: #ffffff;
    @media #{$md},#{$sm},#{$xs} {
      width: 100%;
    }
  }
  &.asset_formation_img{
    border-radius: 30px;
    @media #{$md},#{$sm},#{$xs} {
      width: 100%;
    }
  }
  &.vendor_img{
    @media #{$md},#{$sm},#{$xs} {
      width: 100%;
    }
  }
}
label{
  &.menu-btn{
    display: block;
    span {
      content: '';
      display: block;
      height: 4px;
      width: 34px;
      background-color: #4C3D3A;
      position: absolute;
      &::before {
        content: '';
        display: block;
        height: 4px;
        width: 34px;
        background-color: #4C3D3A;
        position: absolute;
        top: -10px;
      }
      &::after {
        content: '';
        display: block;
        height: 4px;
        width: 34px;
        background-color: #4C3D3A;
        position: absolute;
        top: 10px;
      }
    }
  }
}
input {
  &#menu-btn-check {
    display: none;
    &:checked ~ .menu-btn span {
      @media #{$md},#{$sm},#{$xs} {
        background-color: rgba(255, 255, 255, 0); /*メニューオープン時は真ん中の線を透明にする*/
        &::before {
          top: 0!important;
          transform: rotate(45deg);
        }
        &::after {
          top: 0!important;
          transform: rotate(-45deg);
        }
      }
    }
    &:checked ~ .menu-content {
      @media #{$md},#{$sm},#{$xs} {
        left: 0; /*メニューを画面内へ*/
      }
    }
  }
}

.about_navi_box{
  @media #{$xxl},#{$xl} {
    padding-left: 158px;
  }
  @media #{$lg} {
    padding-left: 72px;
  }
  @media #{$md},#{$sm},#{$xs} {
    padding-top: 32px;
  }
}
.asset_formation_box{
  @media #{$xxl},#{$xl} {
    padding-right: 102px;
  }
  @media #{$lg} {
    padding-right: 13px;
  }
}
.header_next {
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0 4px 15px 0 rgba(32, 32, 32, 0.15);
  position: fixed;
  width: 100%;
  left: 0;
  top: 160px;
  z-index: 999;
  height: 43px;
  @media #{$lg} {
    top: 160px;
    height: 37px;
  }
  @media #{$md} {
    height: auto;
    top: 0;
  }
  @media #{$sm} {
    height: auto;
    top: 10px;
  }
  @media #{$xs} {
    height: auto;
    top: 0;
  }
}
.item_link_box{
  padding-bottom: 42px;
  @media #{$md},#{$sm},#{$xs} {
    padding-bottom: 16px;
  }
}
.copy_box{
  background: #4C3D3A;
  padding: 8px 0;
}
.assets_formation_top{
  margin-top: -80px;
  @media #{$xxl} {
    margin-top: unset;
  }
  @media #{$lg} {
    margin-top: -40px;
  }
  @media #{$md} {
    margin-top: -16px;
  }
  @media #{$sm},#{$xs} {
    margin-top: -14px;
  }
}
.service_box{
  background-color: #F8F8F8;
  padding: 24px;
  margin-bottom: 42px;
  &:last-child{
    margin-bottom: unset;
  }
}
.other_asset{
    padding: 16px 0;
}

.other_asset_contents{
  background-color: #F8F8F8;
  padding: 24px;
  //height: 38rem;
  position: relative;
  text-align: justify;
  @media #{$md} {
    height: 41rem;
  }
  @media #{$sm},#{$xs} {
    height: auto;
  }
}


.anotherpage_index{
  padding-top: 80px;
}
.footer_link_box{
  @media #{$md},#{$sm},#{$xs} {
    background: #F8F8F8;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 32px 37px;
  }
}
.footer_copy{
  display: flex;
  justify-content: space-between;
  @media #{$md},#{$sm},#{$xs} {
    display: grid;
    justify-content: center;
  }
}
footer{
  margin-top: 160px;
  border-top: 10px solid;
  border-image: linear-gradient(90deg, #1C8148 50%, #8ECA55 100%) 1;
  background-color: #F8F8F8;
  position: relative;
  @media #{$md},#{$sm},#{$xs} {
    margin-top: 64px;
  }
}
